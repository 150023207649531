import React from 'react'
import navData from './navigationLinks.yaml'
import Header from 'sparkol-web-component-library/src/components/header'
import PropTypes from 'prop-types'

import './header.scss'

const PageHeader = ({ className }) => {
  return <Header topMenuLinks={navData} className={className} />
}

PageHeader.propTypes = {
  className: PropTypes.string
}

export default PageHeader
