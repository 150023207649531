import React from 'react'
import { Helmet } from 'react-helmet'
import './index.scss'

// @see https://css-tricks.com/dont-just-copy-the-font-face-out-of-google-fonts-urls/
const Typography = () => {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link href="https://fonts.googleapis.com/css2?family=Spartan:wght@800&display=swap" rel="stylesheet"></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}

export default Typography
