import React from 'react'
import TransparentVideo from '../../components/TransparentVideo'
import Video from 'sparkol-web-component-library/src/components/video'
import videoPoster from './video-poster.png'

import './homepageHeroVideo.scss'

const HomepageHeroVideo = props => {
  const videoPosterElement = <img src={videoPoster} className="video__fallback hero__fallback" />

  return (
    <div className="hero__video">
      <TransparentVideo fallback={videoPosterElement}>
        <Video {...props} preload="metadata" />
      </TransparentVideo>
    </div>
  )
}

export default HomepageHeroVideo
