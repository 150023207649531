import { useState, useEffect } from 'react'
import Proptypes from 'prop-types'

import autoplayVideoData from 'raw-loader!./videoTestDataAutoplay.txt'
import transparentVideoData from 'raw-loader!./videoTestDataTransparent.txt'

const createAutoplayTestVideo = () => {
  const testVideo = document.createElement('video')

  const webm = document.createElement('source')
  webm.src = `data:video/webm;base64,${autoplayVideoData}`

  testVideo.appendChild(webm)

  testVideo.id = 'base64_test_video'
  testVideo.setAttribute('autoplay', true)
  testVideo.setAttribute('muted', true)

  testVideo.style.position = 'fixed'
  testVideo.style.left = '5000px'

  document.getElementsByTagName('body')[0].appendChild(testVideo)

  return document.getElementById('base64_test_video')
}

// @see https://stackoverflow.com/questions/29416579/how-to-feature-detect-if-browser-supports-webm-alpha-transparency
const detectWebMAlphaSupport = () => {
  return new Promise((resolve, reject) => {
    const vid = document.createElement('video')
    vid.autoplay = false
    vid.loop = false
    vid.style.display = 'none'

    const source = document.createElement('source')
    source.src = `data:video/webm;base64,${transparentVideoData}`

    source.addEventListener('error', () => {
      reject(new Error('Could not load video'))
      return
    })

    vid.addEventListener(
      'loadeddata',
      () => {
        document.body.removeChild(vid)
        // Create a canvas element, this is what user sees.
        const canvas = document.createElement('canvas')

        //If we don't support the canvas, we definitely don't support webm alpha video.
        if (!(canvas.getContext && canvas.getContext('2d'))) {
          resolve(false)
          return
        }

        // Get the drawing context for canvas.
        const ctx = canvas.getContext('2d')

        // Draw the current frame of video onto canvas.
        ctx.drawImage(vid, 0, 0)
        if (ctx.getImageData(0, 0, 1, 1).data[3] === 0) {
          return resolve(true)
        } else {
          return resolve(false)
        }
      },
      false
    )

    vid.addEventListener('error', () => {
      document.body.removeChild(vid)
      return reject(Error('error'))
    })

    vid.addEventListener('stalled', () => {
      document.body.removeChild(vid)
      return reject(Error('stalled'))
    })

    vid.addEventListener('abort', () => {
      document.body.removeChild(vid)
      return reject(Error('abort'))
    })
    //This is required for IE
    vid.appendChild(source)
    document.body.appendChild(vid)
  })
}

// @see https://stackoverflow.com/questions/7120703/how-do-i-detect-if-the-html5-autoplay-attribute-is-supported
const detectAutoplaySupport = () => {
  return new Promise(resolve => {
    let autoplay = false
    let testVideoElem

    if (!(testVideoElem = document.getElementById('base64_test_video'))) {
      testVideoElem = createAutoplayTestVideo()
    }

    //test for autoplay, 100 ms buffer
    setTimeout(function() {
      // check to see if the video has autoplayed
      if (testVideoElem.paused || testVideoElem.currentTime > 0) {
        autoplay = true
      }

      resolve(autoplay)
    }, 1000)
  })
}

const TransparentVideo = props => {
  let { children, fallback } = props

  const [supportsTransVideo, setSupportsTransVideo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const testBrowser = async () => {
    const root = document.documentElement

    let cachedTransVideoSupportItem
    if ((cachedTransVideoSupportItem = localStorage.getItem('transVideoSupport'))) {
      let transVideoSupportItem = JSON.parse(cachedTransVideoSupportItem)
      setSupportsTransVideo(transVideoSupportItem)
      root.className += transVideoSupportItem ? ' transvideo' : ' no-transvideo'
      setIsLoading(false)
      return
    }

    const browserTests = [detectAutoplaySupport(), detectWebMAlphaSupport()]

    return Promise.all(browserTests)
      .then(([autoplaySupport, webMAlphaSupport]) => {
        let supportsVideo = autoplaySupport && webMAlphaSupport
        setSupportsTransVideo(supportsVideo)
        localStorage.setItem('transVideoSupport', JSON.stringify(supportsVideo))
        root.className += supportsVideo ? ' transvideo' : ' no-transvideo'
        setIsLoading(false)
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err)
        setSupportsTransVideo(false)
        localStorage.setItem('transVideoSupport', JSON.stringify(false))
        setIsLoading(false)
      })
  }

  useEffect(() => {
    testBrowser()
  }, [])

  let renderElem = null
  if (isLoading) {
    renderElem = null
  } else if (supportsTransVideo == true) {
    renderElem = children
  } else {
    renderElem = fallback ? fallback : null
  }

  return renderElem
}

TransparentVideo.propTypes = {
  children: Proptypes.node.isRequired,
  fallback: Proptypes.element
}

export default TransparentVideo
