import React from 'react'

import Section from 'sparkol-web-component-library/src/components/section'
import Button from 'sparkol-web-component-library/src/components/button'

const FeatureBlockSection = props => {
  const ctaButton = <Button url="https://www.scribely.co/app" tag="a" caption="Get Started" style="primary" />

  return <Section {...props} footer={ctaButton} />
}

export default FeatureBlockSection
