import React from 'react'
import PropTypes from 'prop-types'
import Hero from 'sparkol-web-component-library/src/components/hero'
import RichText from 'sparkol-web-component-library/src/components/richText'

import logo from './logo.png'

import './hero.scss'

const ScribelyHero = props => {
  const { richTextBody } = props

  return (
    <Hero {...props}>
      <img className="hero__img" alt="Scribely" src={logo} />
      <div className="hero__body">
        <RichText richTextBody={richTextBody} />
      </div>
    </Hero>
  )
}

ScribelyHero.propTypes = {
  richTextBody: PropTypes.object.isRequired
}

export default ScribelyHero
