import React from 'react'
import Proptypes from 'prop-types'
import util from 'sparkol-web-component-library/src/helpers/util'
import './explainer.scss'
import 'sparkol-web-component-library/src/components/explainer/explainer.scss'
import RichText from 'sparkol-web-component-library/src/components/richText'
import Video from 'sparkol-web-component-library/src/components/video'
import LazyLoad from 'react-lazyload'
import Image from 'gatsby-image'

const Explainer = ({ image, video, title, aligned, imageUrl, richTextBody, button, children }) => {
  const explainerType = aligned === 'left' ? 'left' : 'right'
  let imageProps = {}
  if (image) {
    imageProps = {
      className: 'explainer__image',
      fluid: image.fluid
    }

    if (image.description) {
      imageProps.alt = util.t(image.description)
    }
  }

  const renderImage = () => (
    <LazyLoad height="100%" once>
      <Image {...imageProps} />
    </LazyLoad>
  )

  return (
    <div className={`explainer explainer--${explainerType}`}>
      <div className="explainer__content">
        <div className="explainer__content-container">
          <h2 className="explainer__header explainer__header h3">{util.t(title)}</h2>
          <div className="explainer__body p2">{richTextBody && <RichText richTextBody={richTextBody} />}</div>
        </div>
        {button}
      </div>
      {image && (
        <div className="explainer__media">{imageUrl ? <a href={imageUrl}>{renderImage()}</a> : renderImage()}</div>
      )}
      {video && (
        <div className="explainer__video">
          <Video {...video} preload="metadata" />
        </div>
      )}
      {children && <div className="explainer__media">{children}</div>}
    </div>
  )
}

Explainer.propTypes = {
  image: Proptypes.object,
  video: Proptypes.object,
  imageUrl: Proptypes.string,
  title: Proptypes.string.isRequired,
  richTextBody: Proptypes.object,
  aligned: Proptypes.oneOf(['left', 'right']).isRequired,
  button: Proptypes.element,
  children: Proptypes.node
}

export default Explainer
