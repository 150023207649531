import React from 'react'
import Proptypes from 'prop-types'
import LazyLoad from 'react-lazy-load'
import Image from 'gatsby-image'
import util from 'sparkol-web-component-library/src/helpers/util'
import RichText from 'sparkol-web-component-library/src/components/richText'

const TestimonialExplainer = ({ title, aligned, media, richTextBody }) => {
  const renderImage = (
    <LazyLoad height="100%" once>
      <Image className="explainer__image" alt={util.t(media.description)} fluid={media.fluid} />
    </LazyLoad>
  )
  return (
    <div className={`explainer explainer--${aligned}`}>
      <div className="explainer__content">
        <h1 className="explainer__header">{util.t(title)}</h1>
        <div className="explainer__body">
          <RichText richTextBody={richTextBody} />
        </div>
        <p className="explainer__footer">{util.t(media.description)}</p>
      </div>
      <div className="explainer__media">{renderImage}</div>
    </div>
  )
}

TestimonialExplainer.propTypes = {
  title: Proptypes.string.isRequired,
  body: Proptypes.string,
  richTextBody: Proptypes.object,
  aligned: Proptypes.oneOf(['left', 'right']).isRequired,
  media: Proptypes.object
}

export default TestimonialExplainer
