import React from 'react'
import TransparentVideo from '../../components/TransparentVideo'
import Video from 'sparkol-web-component-library/src/components/video'

import videoPoster from './video-poster.png'
import './inspirationpageHeroVideo.scss'

const PricingpageHeroVideo = props => {
  const videoPosterElement = <img src={videoPoster} className="video__fallback--inspiration" />

  return (
    <div className="hero__video--inspiration">
      <TransparentVideo fallback={videoPosterElement}>
        <Video {...props} preload="metadata" />
      </TransparentVideo>
    </div>
  )
}

export default PricingpageHeroVideo
