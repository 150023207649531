import React, { useEffect } from 'react'
import useScript from 'sparkol-web-component-library/src/hooks/useScript'
import { Helmet } from 'react-helmet'

import './cookieConsentBar.scss'

const CookieConsentBar = () => {
  const [loaded, error] = useScript('https://wpcc.io/lib/1.0.2/cookieconsent.min.js', 2000)

  useEffect(() => {
    if (loaded && !error) {
      // eslint-disable
      window.wpcc.init({
        border: 'thin',
        corners: 'small',
        colors: {
          popup: { background: '#00c8f0', text: '#131515', border: '#00c8f0' },
          button: { background: '#fffbfb', text: '#131515' }
        },
        position: 'bottom',
        content: { href: 'https://help.sparkol.com/support/solutions/articles/1000033748' }
      })
      // eslint-enable
    }
  }, [loaded, error])

  return (
    <Helmet>
      <link rel="dns-prefetch" href="https://wpcc.io" />
    </Helmet>
  )
}

export default CookieConsentBar
