import Button from 'sparkol-web-component-library/src/components/button'
import React from 'react'

const CTAButtons = () => (
  <div className="button-container">
    <Button url="https://www.scribely.co/app" tag="a" caption="Get Started" style="primary" />
    <Button url="https://www.scribely.co/app/auth/signup" tag="a" caption="Join the Scribely tribe" style="secondary" />
  </div>
)

export default CTAButtons
