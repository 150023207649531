/* eslint-disable react/jsx-no-undef */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

import Header from '../containers/header'
import Footer from '../containers/footer'

import util from 'sparkol-web-component-library/src/helpers/util'

import Typography from '../components/Typography'
import Section from '../components/Section'
import HomepageHero from '../components/Hero'
import CookieConsentBar from '../components/CookieConsentBar'
import Favicon from '../components/Favicon'
import SocialExplainer from '../components/SocialExplainer'
import Explainer from '../components/Explainer'
import TestimonialExplainer from '../components/TestimonialExplainer'
import FeatureBlockSection from '../components/FeatureBlockSection'
import CTAWrappedSection from '../components/CTAWrappedSection'
import HomepageHeroVideo from '../components/HomepageHeroVideo'
import Pricing from '../components/Pricing'
import PricingpageHeroVideo from '../components/PricingpageHeroVideo'
import InspirationpageHeroVideo from '../components/InspirationpageHeroVideo'

import Hero from 'sparkol-web-component-library/src/components/hero'
import Layout from 'sparkol-web-component-library/src/components/layout'
import ErrorDisplay from 'sparkol-web-component-library/src/components/errorDisplay'
import RichText, {
  options as richTextOptions,
  BLOCKS as richTextBlocksType
} from 'sparkol-web-component-library/src/components/richText'
import SimpleCard from 'sparkol-web-component-library/src/components/simpleCard'
import Button from 'sparkol-web-component-library/src/components/button'
import Video from 'sparkol-web-component-library/src/components/video'
import Widget from 'sparkol-web-component-library/src/components/widget'

import '../styles/theme.scss'
import { Helmet } from 'react-helmet'

/* eslint-disable react/prop-types */
const explainerFactory = (condition, props) => {
  switch (condition) {
    case 'section--social':
      return <SocialExplainer {...props} richTextBody={props.richTextBody} />
    case 'section--testimonial':
    case 'section--inspiration-testimonial':
      return <TestimonialExplainer {...props} richTextBody={props.richTextBody} />
    default:
      return <Explainer image={props.media} {...props} />
  }
}

const sectionFactory = props => {
  switch (props.cssClass) {
    case 'section--feature-blocks':
      return FeatureBlockSection
    case 'section--video-promo':
    case 'section--inspiration-testimonial':
      return CTAWrappedSection
    default:
      return Section
  }
}

const heroFactory = props => {
  switch (props.cssClass) {
    case 'section--homepage-hero':
      return HomepageHero

    default:
      return Hero
  }
}

const videoFactory = (condition, props) => {
  switch (condition) {
    case 'section--homepage-hero':
      return <HomepageHeroVideo preload="metadata" {...props} />
    case 'section--inspiration-hero':
      return <InspirationpageHeroVideo preload="metadata" {...props} />
    case 'section--pricing-hero':
      return <PricingpageHeroVideo preload="metadata" {...props} />
    default:
      return <Video preload="metadata" {...props} />
  }
}

/*
 * Custom richtext renderer
 */
richTextOptions.renderNode[richTextBlocksType.EMBEDDED_ENTRY] = node => {
  const { fields, sys } = node.data.target
  const { id } = sys.contentType.sys

  switch (id) {
    case 'button': {
      const { style = {}, tag = {}, url = {}, caption = {} } = fields
      return <Button {...{ style: style['en-US'], caption: caption['en-US'], tag: tag['en-US'], url: url['en-US'] }} />
    }

    case 'widget': {
      switch (fields.widget['en-US']) {
        case 'Price':
          return <Pricing className="pricing--block" />
        case 'Inline Price':
          return <Pricing {...fields.data['en-US']} />
      }
    }
  }
}

/* eslint-enable react/prop-types */
const PageTemplate = ({ data }) => {
  // https://support.google.com/optimize/answer/7008840
  useEffect(() => {
    window.dataLayer.push({ event: 'optimize.activate' })
  })

  const { title, slug, metaDescription, image } = data.page

  return (
    <Layout
      title={title}
      slug={slug}
      siteConfig={data.site}
      seo={{
        metaDescription,
        image
      }}
      url={slug}
    >
      <Favicon />
      <Helmet>
        <link rel="dns-prefetch" href="https://videos.ctfassets.net/" />
      </Helmet>
      <Typography />
      <Header className={`header--${slug.replace(/\//gi, '-')}`} />
      <main className="main">
        {data.page.body &&
          data.page.body.map((value, key) => {
            let SectionComponent = sectionFactory(value)
            let HeroComponent = heroFactory(value)

            const classes = classNames(value.cssClass, {
              [`section--gradient-${value.backgroundGradient?.replace(/\s/gi, '-')}`]: value.backgroundGradient
            })

            return (
              <SectionComponent
                key={key}
                style={value.style}
                title={value.title}
                showTitle={value.showTitle}
                backgroundType={value.backgroundType}
                padding={value.padding ? value.padding : undefined}
                className={classes}
                sectionId={value.sectionId}
              >
                {value.component &&
                  value.component.map((item, index) => {
                    let sectionClass = value.cssClass
                    return (
                      <React.Fragment key={index}>
                        <ErrorDisplay
                          error={util.validateObject(item).isNotEmpty}
                          message={util.validateObject(item).message}
                        >
                          <React.Fragment key={index}>
                            {item.__typename == 'ContentfulVideo' && videoFactory(sectionClass, item)}
                            {item.__typename == 'ContentfulLogoList' ? <LogoList {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulButton' ? <Button {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulHero' ? <HeroComponent {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulTeaserCard' ? <TeaserCard {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulSimpleCard' ? <SimpleCard {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulCtaBanner' ? <CTABanner {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulSimpleCtaBanner' ? (
                              <SimpleCTABanner {...item} key={index} />
                            ) : (
                              ''
                            )}
                            {item.__typename == 'ContentfulExplainer' && explainerFactory(sectionClass, item)}
                            {item.__typename == 'ContentfulJumbotron' ? <Jumbotron {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulStat' ? <Stat {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulQuotes' ? <Quote {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulAddress' ? <Address {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulFeedback' ? (
                              <Feedback
                                {...{
                                  bodyText: item.bodyText.internal.content,
                                  title: item.title,
                                  author: item.author
                                }}
                                key={index}
                              />
                            ) : (
                              ''
                            )}
                            {item.__typename == 'ContentfulRichText' ? <RichText {...item} key={index} /> : ''}
                            {item.__typename == 'ContentfulWidget' ? (
                              <Widget widget={item.widget} {...item} key={index} />
                            ) : (
                              ''
                            )}
                            {item.__typename == 'ContentfulListGrid' ? (
                              <ListGrid {...item} key={index}>
                                {item.item &&
                                  item.item.map((value, index) => {
                                    return <ListGridItem key={index} text={value} />
                                  })}
                              </ListGrid>
                            ) : (
                              ''
                            )}
                            {item.__typename == 'ContentfulTable' ? <Table {...item} /> : ''}
                          </React.Fragment>
                        </ErrorDisplay>
                      </React.Fragment>
                    )
                  })}
              </SectionComponent>
            )
          })}
      </main>
      <Footer />
      <CookieConsentBar />
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const query = graphql`
  query PageBySlugQuery($slug: String!) {
    site: contentfulSiteSettings {
      googleAnalyticsId
      hubSpotCode
      author
      icon {
        fixed {
          src
        }
      }
      name
      twitterUsername
    }
    page: contentfulPage(slug: { eq: $slug }) {
      title
      metaDescription
      slug
      image {
        fixed {
          src
          width
          height
        }
        description
      }
      body {
        style
        title
        showTitle
        backgroundType
        sectionId
        cssClass
        backgroundGradient
        padding
        component {
          ... on Node {
            __typename
            id
            ... on ContentfulVideo {
              id
              videoTitle
              videoThumbnail {
                description
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              videoUrl
              showControls
              autoPlay
              loop
              playsInline
              videoAsset {
                file {
                  url
                  contentType
                }
              }
            }
            ... on ContentfulFeedback {
              feedbackTitle: title
              author
              bodyText {
                internal {
                  content
                }
              }
            }
            ... on ContentfulRichText {
              id
              horizontalAlignment
              richTextBody {
                json
              }
            }
            ... on ContentfulButton {
              style
              caption
              url
              tag
            }
            ... on ContentfulLogoList {
              logoListItem {
                description
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
            ... on ContentfulQuotes {
              title
              quotes
              authors
            }
            ... on ContentfulListGrid {
              title
              item
            }
            ... on ContentfulStat {
              number
              unit
              description
              style
            }
            ... on ContentfulAddress {
              country
              telephoneToCall
              telephoneToDisplay
              street
              postTown
              postCode
            }
            ... on ContentfulCtaBanner {
              title
              body
              image {
                description
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              button {
                style
                caption
                url
                tag
              }
            }
            ... on ContentfulSimpleCtaBanner {
              title
              button {
                style
                caption
                url
                tag
              }
            }
            ... on ContentfulTeaserCard {
              id
              title
              richTextBody {
                json
              }
              videoUrl
              image {
                description
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
            ... on ContentfulHero {
              id
              title
              richTextBody {
                json
              }
              videoThumbnail {
                description
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              videoUrl
              buttons {
                style
                caption
                url
                tag
              }
            }
            ... on ContentfulExplainer {
              id
              richTextBody {
                json
              }
              aligned
              title
              media {
                description
                file {
                  url
                  fileName
                  contentType
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              video {
                id
                videoTitle
                videoThumbnail {
                  description
                  fluid {
                    ...GatsbyContentfulFluid
                  }
                }
                videoUrl
                showControls
                autoPlay
                loop
                playsInline
                videoAsset {
                  file {
                    url
                    contentType
                  }
                }
              }
            }
            ... on ContentfulSimpleCard {
              id
              title
              richTextBody {
                json
              }
              image {
                description
                fixed(height: 140) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            ... on ContentfulWidget {
              widget
              data {
                store
                formId
                portalId
              }
            }
          }
        }
      }
    }
  }
`
