import React from 'react'
import Proptypes from 'prop-types'
import Button from 'sparkol-web-component-library/src/components/button'
import Video from 'sparkol-web-component-library/src/components/video'
import Explainer from '../Explainer'
import LazyLoad from 'react-lazy-load'
import TransparentVideo from '../../components/TransparentVideo'
import Pricing from '../../components/Pricing'

const SocialExplainer = ({ title, richTextBody, aligned, media }) => {
  const ctaButton = (
    <Button caption="Try it for free" style="primary" tag="a" url="https://www.scribely.co/app/auth/signup" />
  )

  return (
    <>
      <Pricing className="pricing--sticker" />
      <Explainer title={title} richTextBody={richTextBody} aligned={aligned} button={ctaButton}>
        <>
          <h1 className="explainer__title">
            Create
            <br />
            Engaging
            <br />
            Experiences
          </h1>
          <TransparentVideo>
            <LazyLoad height={762} offsetVertical={-300}>
              <Video
                preload="auto"
                videoAsset={media}
                autoPlay={true}
                videoTitle={media.file.description}
                playsInline={true}
                className="explainer__video"
              />
            </LazyLoad>
          </TransparentVideo>
        </>
      </Explainer>
    </>
  )
}

SocialExplainer.propTypes = {
  title: Proptypes.string.isRequired,
  richTextBody: Proptypes.object,
  aligned: Proptypes.oneOf(['left', 'right']).isRequired,
  children: Proptypes.node,
  media: Proptypes.object
}

export default SocialExplainer
