import React from 'react'
import Section from 'sparkol-web-component-library/src/components/section'

import './section.scss'

const ScribelySection = props => {
  return <Section {...props} />
}

export default ScribelySection
