import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const Favicon = () => {
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('favicon-16x16.png')} />
      <link rel="manifest" href={withPrefix('site.webmanifest')} />
      <link rel="mask-icon" href={withPrefix('safari-pinned-tab.svg')} color="#00c8f0" />
      <meta name="msapplication-TileColor" content="#00c8f0" />
      <meta name="theme-color" content="#ffffff"></meta>
      <link rel="shortcut icon" href={withPrefix('favicon.ico')}></link>
    </Helmet>
  )
}

export default Favicon
