// https://www.smiffysplace.com/stars.html

import React, { useEffect, useState } from 'react'
import useScript from 'sparkol-web-component-library/src/hooks/useScript'
import classNames from 'classnames'
import Proptypes from 'prop-types'

import './pricing.scss'
import countryData from './countries.yaml'

const sessionStorageKey = 'Scribe_location'
const geocodeScriptSrc = 'https://js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js'

const Pricing = ({ className, displayInline, displayInlineBeforeText }) => {
  let [pricingData, setPricingData] = useState(null)
  const [loaded, error] = useScript(geocodeScriptSrc, 0)
  const [determiningPrice, setDeterminingPrice] = useState(true)

  const setPrice = data => {
    setPricingData(data)
    setDeterminingPrice(false)
  }

  useEffect(() => {
    let pricingCache
    setDeterminingPrice(true)
    if ((pricingCache = sessionStorage.getItem(sessionStorageKey))) {
      setPrice(JSON.parse(pricingCache))
    } else if (loaded && !error) {
      if (typeof geoip2 === 'undefined') {
        setPrice(false)
        return
      }

      // eslint-disable-next-line
      geoip2.country(res => {
        let defaultCountryData = countryData.countries.find(location => {
          return location.isoCode === res.country.iso_code
        })

        if (!defaultCountryData) {
          setPrice(null)
          return
        }

        let { isoCode: country, currency } = defaultCountryData

        defaultCountryData = {
          country,
          currency
        }

        sessionStorage.setItem(sessionStorageKey, JSON.stringify(defaultCountryData))
        setPrice(defaultCountryData)
      })
    }
  }, [loaded])

  useEffect(() => {
    if (error) {
      setDeterminingPrice(false)
    }
  }, [error])

  if (determiningPrice) {
    return null
  }

  let displayPrice = pricingData ? countryData.pricing[pricingData.currency] : countryData.pricing['USD']
  let displayTax = pricingData ? countryData.taxName[pricingData.currency] : countryData.taxName['USD']
  let fallBackPrices = pricingData
    ? ''
    : `(or ${countryData.pricing['GBP']} + ${countryData.taxName['GBP']} / ${countryData.pricing['EUR']} + ${countryData.taxName['EUR']}) `

  let classes = classNames('pricing', className)
  const isSticker = className === 'pricing--sticker'

  if (displayInline) {
    const inlinePrice = pricingData
      ? `${displayPrice} + ${displayTax} per month`
      : `${countryData.pricing['USD']} + ${countryData.taxName['USD']} (or  ${countryData.pricing['GBP']} + ${countryData.taxName['GBP']} / ${countryData.pricing['EUR']} + ${countryData.taxName['EUR']}) per month`

    return (
      <h5>
        {displayInlineBeforeText}
        {inlinePrice}
      </h5>
    )
  }

  return (
    <>
      {!isSticker && <hr />}
      <div className={classes}>
        <div className="pricing__price">
          {displayPrice}
          <small className="pricing__tax-notice">+ {displayTax}</small>
          <p className="pricing__period">
            <small>{fallBackPrices}</small>
            <span>per month</span>
          </p>
        </div>
      </div>
      {!isSticker && <hr />}
    </>
  )
}

Pricing.propTypes = {
  className: Proptypes.string,
  displayInline: Proptypes.bool,
  displayInlineBeforeText: Proptypes.string
}

export default Pricing
